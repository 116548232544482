import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, filter, shareReplay, take, tap } from 'rxjs/operators';
import { UserInterface } from '../interfaces/user.interface';
import { ResourceService } from './resource.service';
import { RolesStore } from '../stores/roles.store';
import { UserStore } from '../stores/user.store';
import { ResourcesEndpointEnum } from '../enums/resources-endpoint.enum';
import { AuthEventsService } from './auth-events.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject = new BehaviorSubject<UserInterface | null>(null);
  public user$ = this.userSubject.asObservable().pipe(shareReplay(1));
  private userDataCache: Observable<UserInterface> | null = null;

  constructor(
    private rolesStore: RolesStore,
    private resourceService: ResourceService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private userStore: UserStore,
    private authEventsService: AuthEventsService
  ) {
    this.initializeUserData();
    this.authEventsService.unauthorizedError$.subscribe(() => {
      this.handleUnauthorizedError();
    });
    this.authEventsService.forbiddenError$.subscribe(() => {
      this.handleForbiddenError();
    });
  }

  private initializeUserData(): void {
    if (this.isLoggedIn()) {
      this.getUserData().subscribe();
    }
  }

  public havePremiumLicense(): Observable<boolean> {
    return this.user$.pipe(
      map(user => user?.IsPremium)
    );
  }
  public doesntHavePremiumLicense(): Observable<boolean> {
    return this.user$.pipe(
      map(user => !user?.IsPremium)
    );
  }
  public isAdmin(): Observable<boolean> {
    return this.user$.pipe(
      map(user => user?.GlobalAdmin)
    );
  }
  public isNotAdmin(): Observable<boolean> {
    return this.user$.pipe(
      map(user => !user?.GlobalAdmin)
    );
  }
  public isOwner(): Observable<boolean> {
    return this.user$.pipe(
      map(user => user?.OwnerOfGroup)
    );
  }
  public isNotOwner(): Observable<boolean> {
    return this.user$.pipe(
      map(user => !user?.OwnerOfGroup)
    );
  }
  public getUserData(): Observable<UserInterface> {
    // Return cached observable if available
    if (this.userDataCache) {
      return this.userDataCache;
    }

    this.userDataCache = this.resourceService.get<UserInterface>(ResourcesEndpointEnum.GET_ROLE).pipe(
      tap(user => {
        this.userSubject.next(user);
        this.userStore.user = user;
        //For testing purposes
        // this.user$.forEach(user => {
        //    user.IsPremium = false;
        //    user.GlobalAdmin = true;
        //    user.OwnerOfGroup = true;
        // });
        // Set roles based on user data
        if (user.GlobalAdmin) {
          this.rolesStore.roles = ['Admin', 'Owner'];
        }
        else if (user.OwnerOfGroup) {
          this.rolesStore.roles = ['Owner'];
        } else {
          this.rolesStore.roles = ['User'];
        }
      }),
      catchError(error => {
        this.userDataCache = null; // Clear cache on error
        return throwError(() => error);
      }),
      shareReplay(1) // Share the same result with all subscribers
    );

    return this.userDataCache;
  }

  setLoginDisplay(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  public isLoggedIn() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
    const account = this.msalService.instance.getActiveAccount();
    return !!account;
  }

  public login() {
    this.msalService.initialize().subscribe((x) => {
      this.msalService.instance.loginRedirect();
      this.router.navigate(['/home']);
    })
  }

  public signOut() {
    this.userDataCache = null;
    this.userSubject.next(null);
    this.userStore.user = null;
    this.rolesStore.roles = [];
    this.msalService.logoutRedirect();
  }

  handleAdminGuardRouting(): void {
    this.msalBroadcastService.msalSubject$.pipe(
      take(1),
      tap(() => {
        if (this.setLoginDisplay()) {
          this.router.navigate(['home']);
        }
      })
    ).subscribe();
  }

  private handleUnauthorizedError(): void {
    this.signOut();
  }

  private handleForbiddenError(): void {
    this.router.navigate(['/home']);
  }

}
