export const environment = {
    production: true,
    apiUrl: '/api',
    auth: {
        clientId: '066d239a-1f77-4085-b393-824b1f9767a9',
        tenantId: '49a661b6-17f0-4997-a9c7-994681dd4691',
        redirectUri: 'https://capp-portal-frontend.victoriousground-acff0e28.norwayeast.azurecontainerapps.io/home',
        postLogoutRedirectUri: 'https://capp-portal-frontend.victoriousground-acff0e28.norwayeast.azurecontainerapps.io/login'
    },
    azureFunctionsKey: 'uo7IvbM5xz0EBpN-eMPNPK8IU7ZjOWDNQHUvXLhysgxvAzFu9P6XnA==',
    resources: {
        portalAppAPI: {
        resourceUri: 'https://ca-kundeportal-demo.victoriousground-acff0e28.norwayeast.azurecontainerapps.io/api',
        // resourceScopes: [ 'profile openid email User.Read' ]
        resourceScopes: [ 'api://IngraphicKundeportalAPIv3/access_as_user' ]
        }
    },
    blobStorageIllustrationPhotos: 'https://stingraphickundeportal.blob.core.windows.net/illustration-photos',
    blobStorageConfigSetting: 'https://stingraphickundeportal.blob.core.windows.net/config-files/ConfigSettings.txt'
};
// https://polite-river-09c8c6b03.2.azurestaticapps.net/
