import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthEventsService } from '../services/auth-events.service';


export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastrService = inject(ToastrService);
  const translateService = inject(TranslateService);
  const authEventsService = inject(AuthEventsService);

  return next(req).pipe(
    catchError((err) => {
      let errorMessage = "";
      if (err.status === 401) {
        errorMessage = "Unauthorized. The provided credentials are invalid. Please try signing in again.";
        authEventsService.emitUnauthorizedError();
      }
      else if (err.status === 403) {
        errorMessage = "Forbidden. User does not have access to this resource";
        authEventsService.emitForbiddenError();
      }
      else if (err.status === 500) {
        // console.log('err in 500: ', err)
        errorMessage = "Internal Server Error. Please contact IT.";
      }
      else if (err.status === 0) {
        errorMessage = "Could not connect to server. Please contact IT.";
      }
      else if (err.status === 400) {
        errorMessage = err.error;
      }
      return throwError(() => {
        translateService.get(errorMessage).subscribe(t => {
          toastrService.error(t)
        })
      });
    })
  );
};
