import { Component, ViewEncapsulation } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
  selector: 'pbi-loader',
  templateUrl: './pbi-loader.component.html',
  styleUrls: ['./pbi-loader.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.ShadowDom
})
export class PBILoaderComponent {
  illustrationPhoto: string;
  constructor(private configService: ConfigService) {
    this.illustrationPhoto = this.configService.getSettings("msal.blobStorageIllustrationPhotos");
  }
}