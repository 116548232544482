import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourcesEndpointEnum } from '../enums/resources-endpoint.enum';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  startDateSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly baseUrl: string;
  private readonly configUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = this.configService.getSettings("msal.resources.portalAppAPI.resourceUri");
    this.configUrl = this.configService.getSettings("msal.blobStorageConfigSetting");
  }

  get<T>(endpoint: ResourcesEndpointEnum, params?: object): Observable<T> {
    return this.http.get(this.createURI(endpoint, params)).pipe(
      map((response: object) => response as unknown as T)
    );
  }

  getCollection<T>(endpoint: ResourcesEndpointEnum, params?: object): Observable<T[]> {
    return this.http.get(this.createURI(endpoint, params), {
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 204) {
          return [];
        } else {
          return response.body;
        }
      })
    );
  }

  post<T>(endpoint: ResourcesEndpointEnum, payload: any, params?: object): Observable<T> {
    return this.http.post(this.createURI(endpoint, params), payload).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  put<T>(endpoint: ResourcesEndpointEnum, payload: any, params?: object): Observable<T> {
    return this.http.put(this.createURI(endpoint, params), payload).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  delete<T>(endpoint: ResourcesEndpointEnum, params?: object): Observable<T> {
    return this.http.delete(this.createURI(endpoint, params)).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  getConfiguration<T>(): Observable<T> {
    return this.http.get(this.configUrl).pipe(
      map((response: object) => response as unknown as T)
    );
  }

  private createURI(uri: string, params?: object): string {
    if (!params) {
      return this.baseUrl + uri;
    }

    const urlArray = uri.split('/');

    urlArray.forEach((segment: any, index: number) => {
      if (segment.charAt(0) === ':') {
        urlArray[index] = params[segment.substr(1)];
      }
    });

    return this.baseUrl + urlArray.join('/');
  }
}
