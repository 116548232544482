import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as pbi from 'powerbi-client';
import { IDashboardEmbedConfiguration, IEmbedConfiguration } from 'powerbi-client';
import { ToastrService } from 'ngx-toastr';
import { WindowSizes } from 'src/app/core/enums/window-sizes.enum';
import { LayoutShowCaseState } from '../interfaces/layout-show-case-state';
import { ResourceService } from './resource.service';

@Injectable({
  providedIn: 'root',
})
export class PowerbiService {

  exportToPDFAndDownload(reportId: string) {
    throw new Error('Method not implemented.');
  }
  public embedder: pbi.Report | pbi.Dashboard;
  public config: IEmbedConfiguration;
  public configDashboard: IDashboardEmbedConfiguration;
  public models = pbi.models;
  public settings: any;
  pageView: any;
  windowSizes = WindowSizes;
  public windowwidth = window.innerWidth;
  LayoutShowcaseState: LayoutShowCaseState;
  ColumnsNumber = {
    One: 1,
    Two: 2,
    Three: 3,
  };

  LayoutShowcaseConsts = {
    margin: 15,
    minPageWidth: 270,
  };
  public powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );

  chosenReport: any;
  visuals: any;
  contentType: string;
  filterSignal = signal([''])

  constructor(private httpClient: HttpClient, private toastrService: ToastrService, public resourceService: ResourceService) { }

  public async retrieveEmbedObject(embedConfig: any) {
    this.settings = {
      // background: this.models.BackgroundType.Transparent,
      // visualRenderedEvents: true,
      background: this.models.BackgroundType.Default,
      panes: {
        filters: {
          visible: false
        },
        pageNavigation: {
          visible: true
        }
      }
    };

    this.contentType = embedConfig.EmbedType !== 'Dashboard' ? 'report' : 'dashboard';
    if (this.contentType == 'dashboard') {
      this.setEmbedConfig(embedConfig, this.contentType);
      this.showDashboard();
    };
    if (this.contentType === 'report') {
      if (this.windowwidth < this.windowSizes.MaxMobileWidth) {
        this.settings.layoutType = this.models.LayoutType.MobilePortrait;
      } else {
        this.settings.layoutType = this.models.LayoutType.MobileLandscape;
        this.setEmbedDisplaySettings(this.models.DisplayOption.FitToPage);
      }
      this.setEmbedConfig(embedConfig, this.contentType);
      this.showReport();
    }
  }

  private setEmbedConfig(embedConfig: any, powerbiContentType: string): void {
    if (powerbiContentType === 'report') {
      this.config = {
        tokenType: this.models.TokenType.Embed,
        accessToken: embedConfig.EmbedToken.Token,
        permissions: this.models.Permissions.All,
        embedUrl: embedConfig.EmbedUrl,
        id: embedConfig.Id,
        type: powerbiContentType,
        settings: this.settings
      };
    }
    else if (powerbiContentType === 'dashboard') {
      this.configDashboard = {
        tokenType: this.models.TokenType.Embed,
        accessToken: embedConfig.EmbedToken.Token,
        permissions: this.models.Permissions.All,
        embedUrl: embedConfig.EmbedUrl,
        id: embedConfig.Id,
        type: powerbiContentType,
        pageView: 'oneColumn',

      };
    }
  }
  public setReportSize(displayOption: any) {
    if (this.contentType === 'report') {
      this.setEmbedDisplaySettings(displayOption);
      const reportContainer = document.getElementById('reportContainer') as HTMLElement;
      this.chosenReport = this.powerbi.get(reportContainer);
      this.chosenReport.updateSettings(this.settings);
    }
  }

  private setEmbedDisplaySettings(displayOption: number) {
    this.LayoutShowcaseState = {} as LayoutShowCaseState;
    this.settings = {
      navContentPaneEnabled: false,
      filterPaneEnabled: false,
      layoutType: this.models.LayoutType.Custom,
      customLayout: {
        displayOption: displayOption
      },
    };
    if (this.windowwidth < this.windowSizes.MaxMobileWidth) {
      this.LayoutShowcaseState.columns = this.ColumnsNumber.One;
    }
  }

  public toggleEditMode(editMode: boolean) {
    if (this.embedder instanceof pbi.Report) {
      if (editMode) {
        this.embedder.switchMode('edit');
      } else {
        this.embedder.switchMode('view');
      }
    }
  }

  // private downloadFile(url: string){
  //   return  this.httpClient.get(url, {reportProgress: true, responseType: 'blob'});
  // }
  
  public async reload() {
    this.embedder.reload();
  }

  async showDashboard() {
    const dashboardContainer = document.getElementById('dashboardContainer') as HTMLElement;

    this.embedder = this.powerbi.embed(dashboardContainer, this.configDashboard) as pbi.Dashboard;
    this.fixIframes();
    this.powerbi.reset(this.embedder as any);
  }

  showReport() {
    const reportContainer = document.getElementById('reportContainer') as HTMLElement;
    const loaderText1 = document.getElementById('pbi-loader-text-1') as HTMLElement;
    const loaderText2 = document.getElementById('pbi-loader-text-2') as HTMLElement;
    const loaderAnimation = document.getElementById('pbi-loader') as HTMLElement;

    this.embedder = this.powerbi.embed(reportContainer, this.config) as pbi.Report;
    this.fixIframes();

    reportContainer.style.visibility = 'hidden';

    this.embedder.on("loaded", () => {
      loaderText1.style.visibility = 'hidden';
      loaderText2.style.visibility = 'visible';
      this.embedder.off("loaded");
      setTimeout(() => {
        reportContainer.style.visibility = 'visible';
        loaderText2.style.visibility = 'hidden';
        loaderAnimation.style.visibility = 'hidden';
      }, 2000);
    });
  }

  /**Helping method to remove the border around the iframe  */
  fixIframes() {
    try {
      const iFrameElements = window.document.getElementsByTagName('iframe');
      if (iFrameElements) {
        for (let i = 0; i < iFrameElements.length; i++) {
          iFrameElements[i].style.border = '0'; //  For other browsers.
          iFrameElements[i].setAttribute('frameBorder', '0'); //  For other browsers (just a backup for the above).
          // iFrameElements[i].setAttribute('background', 'transparent');
          // iFrameElements[i].setAttribute('background-color', 'transparent');
          // iFrameElements[i].style.backgroundColor = 'transparent';
          // iFrameElements[i].style.background = 'transparent';
        }
      }
    } catch (Exception) {
      // console.log('Error fixing Iframes', Exception);
    }
  }
  public showFullscreen() {
    this.embedder.fullscreen();
  }
  public refresh() {
    if (this.embedder instanceof pbi.Report) {
      this.embedder.refresh();
    }
  }


  public print() {
    if (this.embedder instanceof pbi.Report) {
      this.embedder
        .print()
        .catch((error) => console.log('Error when printing: ', error));
    }
  }

}

