import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInterface } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserStore {
  private readonly _user$: BehaviorSubject<UserInterface | null> = new BehaviorSubject<UserInterface | null>(null);

  get user$(): Observable<UserInterface | null> {
    return this._user$.asObservable();
  }

  set user(user: UserInterface | null) {
    this._user$.next(user);
  }
}
