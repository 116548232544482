import { ChangeDetectorRef, Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';
import { FrontendConfig } from './core/interfaces/frontend-config';
import { ResourceService } from './core/services/resource.service';
import { ThemeService } from './core/services/theme.service';
import { throwError } from 'rxjs';
import { ConfigurationStore } from './core/stores/configuration.store';
import { ConfigService } from './core/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, NgIf]
})
export class AppComponent {
  isIframe = false;
  configuration: FrontendConfig;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  illustrationPhoto: string;

  constructor(private resourceService: ResourceService,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService,
    private configurationStore: ConfigurationStore,
    private config: ConfigService) {
    this.illustrationPhoto = this.config.getSettings("msal.blobStorageIllustrationPhotos");
  }

  ngOnInit(): void {

    this.favIcon.href = this.illustrationPhoto + '/FavIcon.png';
    this.isIframe = window !== window.parent && !window.opener;
    this.getConfiguration();
  }

  getConfiguration() {
    this.resourceService.getConfiguration<FrontendConfig>().subscribe({
      next: (response: FrontendConfig) => {
        this.configuration = response;
        this.configurationStore.config = response;
        if (this.configuration.ColorThemeModeString !== 'Dark') {
          this.themeService.setLightTheme();
          this.cdr.detectChanges();
        }
      },
      error: (err) => {
        throwError(() => err);
      },
      complete: () => { }
    })
  }
}
