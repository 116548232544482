import { AppComponent } from './app/app.component';
import { appConfig } from './app.config';
import { bootstrapApplication } from "@angular/platform-browser";
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.log(err));
