import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor() { }

  public searchFunction(keys: string[], event, list?, arrays?: any[]) {
    const val = event.target.value.toString().toLowerCase();
    const count = Object.keys(keys).length;
    const arrayCount = arrays?.length;
    return list?.filter(item => {
      for (let i = 0; i < count; i++) {
        if ((item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(val) !== -1) || !val) {
          return true;
        }
      }
      for (let j = 0; j < arrayCount; j++) {
        if ((item[arrays[j]] && item[arrays[j]].toString().toLowerCase().indexOf(val) !== -1) || !val) {
          return true;
        }
      }
      if (!val) {
        return
      }
    })
  }
}
