import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AlertController, ModalController, IonicModule } from '@ionic/angular';
import { FormHelper } from 'src/app/core/helpers/form.helper';
import { ReportConfig } from 'src/app/core/interfaces/report-config';
import { NgIf } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ResourceService } from 'src/app/core/services/resource.service';
import { throwError } from 'rxjs';
import { ResourcesEndpointEnum } from 'src/app/core/enums/resources-endpoint.enum';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ReactiveFormsModule,
    NgIf,
    TranslateModule
  ],
})
export class ReportModalComponent implements OnInit {
  @Input() report?: ReportConfig;
  @Input() groupID?: number;
  @Input() groupImg?: string;
  responseNewLogo: any;

  file: File = null;
  reportForm!: FormGroup;
  reportFormProcessing = false;
  reportFormSubmitted = false;
  hasRLS = false;
  orderForm: FormGroup;
  formHelper = FormHelper;

  constructor(public modalController: ModalController,
    private resourceService: ResourceService,
    private toastrService: ToastrService,
    private alertController: AlertController,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.hasRLS = this.report.RLS ? this.report.RLS : false;
    this.buildForm();
  }

  buildForm() {
    this.reportForm = this.formBuilder.group({
      ReportID: [this.report.ReportID],
      Title: [this.report ? this.report.Title : null, [Validators.required]],
      ReportGUID: [this.report ? this.report.ReportGUID : null, [Validators.required]],
      ReportType: this.formBuilder.group({
        ReportTypeID: [this.report.ReportType.ReportTypeID],
        ReportType: [this.report.ReportType.ReportType]
      }),
      Description: [this.report ? this.report.Description : null],
      MobileFriendly: [this.report ? this.report.MobileFriendly : null],
      RLS: [this.report ? (this.report.RLS ? this.report.RLS : false) : null],
      RLSRoleName: [this.report ? (this.report.RLSRoleName ? this.report.RLSRoleName : "") : null],
      RLSFilteredOnEmail: [this.report ? (this.report.RLSFilteredOnEmail ? this.report.RLSFilteredOnEmail : false) : false],
      Published: [this.report ? this.report.Published : null, [Validators.required]],
      Pinned: [this.report ? this.report.Pinned : null]
    });
  }

  uploadLogo(event) {
    this.file = event.target.files[0]

    if (this.file) {
      if (this.isSVGLogo(this.file)) {
        this.translateService.get('Logo File Format should be png, jpeg, jpg').subscribe(t => {
          this.toastrService.error(t)
        })
      } else {
        this.resourceService.post<any>(ResourcesEndpointEnum.UPLOAD_REPORT_IMAGE, this.file, { groupID: this.groupID, reportID: this.report.ReportID, fileName: this.file.name })
          .subscribe({
            next: (response: any) => {
              this.responseNewLogo = response
              this.cdr.detectChanges();
              this.translateService.get('Report Image upload successful').subscribe(t => {
                this.toastrService.success(t);
              })
            },
            error: (err) => {
              this.translateService.get('Error when uploading Report Image').subscribe(t => {
                this.toastrService.error(t);
              })
              throwError(() => err);
            },
            complete: () => { }
          });
      }
    }
  }

  private isSVGLogo(file: any): boolean {
    let isSVG = false
    if (file.type.includes('svg')) {
      return isSVG = true
    }
    return isSVG
  }

  saveReport(): void {
    this.reportFormProcessing = true;
    this.reportFormSubmitted = true;
    if (this.reportForm.invalid) {
      this.reportFormProcessing = false;
      return;
    }

    const reportForm = this.reportForm.value;
    reportForm.ImageURI = this.responseNewLogo ? this.responseNewLogo.ImageURI : this.report.ImageURI;
    void this.modalController.dismiss({
      reportForm,
      action: this.report ? 'edit' : 'create'
    });
  }

  // testEmbedding(): void {
  //   this.translateService.get(['Test Embedding?', 'Make sure the settings are saved.', 'Will open report in a new tab.', 'Cancel', 'Continue']).subscribe(t => {
  //     this.alertController.create({
  //       header: t['Test Embedding?'],
  //       cssClass: 'custom-alert',
  //       message: t['Make sure the settings are saved.'] + '<br><br>' + t['Will open report in a new tab.'],
  //       buttons: [
  //         {
  //           text: t['Cancel'],
  //           role: 'cancel'
  //         },
  //         {
  //           text: t['Continue'],
  //           handler: () => {
  //             this.openEmbeddingInNewTab();
  //           }
  //         }
  //       ]
  //     }).then((alert) => {
  //       void alert.present();
  //     });
  //   })
  // }

  // openEmbeddingInNewTab(): void {
  //   window.open(`home/reports/${this.report.ReportID}/${this.report.ReportType.ReportType}?id=${this.groupID}`, '_blank');
  // }


  onBack() {
    this.modalController.dismiss(false);
  }

  enabledRLS(event: any) {
    if (event.detail.checked)
      this.hasRLS = true
    else
      this.hasRLS = false
  }
}
