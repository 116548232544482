import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() { }

  setLightTheme() {
    document.documentElement.style.setProperty('--ion-color-primary', '#d8e1e5'); // Main background color
    document.documentElement.style.setProperty('--ion-color-primary-contrast', 'black'); // A contrast color for the primary background color
    document.documentElement.style.setProperty('--ion-color-secondary', '#f3f3f3'); // Secondary color, the color used on cards that appear directly on the background
    document.documentElement.style.setProperty('--ion-color-secondary-contrast', 'black'); // A contrast color for the secondary background color
    document.documentElement.style.setProperty('--ion-color-tertiary', 'white'); // Tertiary color, a light color that is used on top of the secondary color
    document.documentElement.style.setProperty('--ion-color-tertiary-contrast', 'black'); // A contrast color for the tertiary color
    document.documentElement.style.setProperty('--ion-color-quartiary', '#cdd0d2'); // Quartiary color, a light color that is used on top of the tertiary color
    document.documentElement.style.setProperty('--ion-color-success', '#045a8f'); // Success color, a color that indicates success, should be Green by convention
    document.documentElement.style.setProperty('--ion-color-warning', '#f5b521'); // Warning color, a color that indicates a warning, should be Yellow by convention
    document.documentElement.style.setProperty('--ion-color-danger', '#c03e3e'); // Danger color, a color that indicates danger, should be Red by convention
    document.documentElement.style.setProperty('--ion-color-medium', '#6aa1bb'); // Medium color, a color that is used as a accent color, like buttons etc
    document.documentElement.style.setProperty('--ion-color-medium-contrast', '#ffffff'); // A contrast color for the medium color
    document.documentElement.style.setProperty('--ion-color-light', '#f4f5f8'); // Light color, a color that is used as a accent color, like buttons etc
    document.documentElement.style.setProperty('--ion-color-notFavorite', '#d8e1e5'); // Color of the favorite star when favorite = false
    document.documentElement.style.setProperty('--ion-text-color', 'black'); // Text color, the color of the default text, if any contrast color is not used instead
    document.documentElement.style.setProperty('--ion-color-loadingSpinner', 'black'); // Loading spinner color, the color of the deafult loading spinner
  }

  setDarkTheme() {
    document.documentElement.style.setProperty('--ion-color-primary', '#001020'); // Main background color
    document.documentElement.style.setProperty('--ion-color-primary-contrast', '#ffffff'); // A contrast color for the primary background color
    document.documentElement.style.setProperty('--ion-color-secondary', '#001B2C'); // Secondary color, the color used on cards that appear directly on the background
    document.documentElement.style.setProperty('--ion-color-secondary-contrast', '#ffffff'); // A contrast color for the secondary background color
    document.documentElement.style.setProperty('--ion-color-tertiary', '#02253b'); // Tertiary color, a light color that is used on top of the secondary color
    document.documentElement.style.setProperty('--ion-color-tertiary-contrast', '#ffffff'); // A contrast color for the tertiary color
    document.documentElement.style.setProperty('--ion-color-quartiary', '#141b20'); // Quartiary color, a light color that is used on top of the tertiary color
    document.documentElement.style.setProperty('--ion-color-success', '#68b984'); // Success color, a color that indicates success, should be Green by convention
    document.documentElement.style.setProperty('--ion-color-warning', '#f5b521'); // Warning color, a color that indicates a warning, should be Yellow by convention
    document.documentElement.style.setProperty('--ion-color-danger', '#c03e3e'); // Danger color, a color that indicates danger, should be Red by convention
    document.documentElement.style.setProperty('--ion-color-medium', '#a4b1b1'); // Medium color, a color that is used as a accent color, like buttons etc
    document.documentElement.style.setProperty('--ion-color-medium-contrast', '#ffffff'); // A contrast color for the medium color
    document.documentElement.style.setProperty('--ion-color-light', '#f4f5f8'); // Light color, a color that is used as a accent color, like buttons etc
    document.documentElement.style.setProperty('--ion-color-notFavorite', '#f4f5f8'); // Color of the favorite star when favorite = false
    document.documentElement.style.setProperty('--ion-text-color', '#ffffff'); // Text color, the color of the default text, if any contrast color is not used instead
    document.documentElement.style.setProperty('--ion-color-loadingSpinner', '#e2e2e2'); // Loading spinner color, the color of the deafult loading spinner
  }
}
