import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, InjectionToken } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { MsalInterceptor, MSAL_INSTANCE, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { CustomHeaderInterceptor } from './app/core/interceptors/custom-header.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app/app-routing.module';
import { SharedModule } from './app/shared/shared.module';
import { IonicModule } from '@ionic/angular';
import { QuillConfigModule } from 'ngx-quill';
import { errorInterceptor } from './app/core/interceptors/error.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConfigService } from './app/core/services/config.service';

const configLocation = "./assets/configuration.json";
const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>(configLocation);

export function initializerFactory(env: ConfigService, configUrl: string): any {
  const promise = env.init(configUrl).then((value) => {
    // console.log("finished getting configurations dynamically.");
  });
  return () => promise;
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(config: ConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: config.getSettings("msal").auth,
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}
  
export function MSALInterceptorConfigFactory(config: ConfigService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(config.getSettings("msal.resources.portalAppAPI.resourceUri"), config.getSettings("msal.resources.portalAppAPI.resourceScopes"));

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(config: ConfigService): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: config.getSettings("msal.resources.portalAppAPI.resourceScopes"),
    },
    loginFailedRoute: "/login-failed"
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      CommonModule,
      AppRoutingModule,
      SharedModule,
      ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
      IonicModule.forRoot({ innerHTMLTemplatesEnabled: true, animated: true, mode: 'md' }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      QuillConfigModule.forRoot({
        modules: {
          // blotFormatter: { specs: [CustomImageSpec] },
          syntax: false,
          toolbar: [
            ['bold', 'italic', 'underline'], // toggled buttons
            // ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }], // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
            // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction
            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean'], // remove formatting button
            ['link', 'image', 'video']
          ]
        }
      })
    ),
    ConfigService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideHttpClient(withInterceptors([errorInterceptor ])),
    provideNoopAnimations(),
    { provide: AUTH_CONFIG_URL_TOKEN, useValue: configLocation },
    { provide: APP_INITIALIZER, useFactory: initializerFactory, deps: [ConfigService, AUTH_CONFIG_URL_TOKEN], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory, deps: [ConfigService] },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory, deps: [ConfigService] },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory, deps: [ConfigService] },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHeaderInterceptor, multi: true }
  ]
};
