import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { ResourcesEndpointEnum } from 'src/app/core/enums/resources-endpoint.enum';
import { ReportConfig } from 'src/app/core/interfaces/report-config';
import { ResourceService } from 'src/app/core/services/resource.service';
import { NgIf, NgStyle } from '@angular/common';
import { IonicModule, PopoverController } from '@ionic/angular';
import { RouterLink } from '@angular/router';
import { ConfigurationPopoverComponent } from '../configuration-popover/configuration-popover.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RequiredRolesDirective, DirectiveCondition, DirectiveRole } from 'src/app/core/directives/required-roles.directive';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    IonicModule,
    RouterLink,
    NgStyle,
    TranslateModule,
    RequiredRolesDirective
  ],
})

export class ReportCardComponent implements OnInit {
  @Input()
  report!: ReportConfig;
  @Input()
  groupId!: number;
  @Input()
  onMobileView: boolean;
  isPremium = false;
  DirectiveRole = DirectiveRole;
  DirectiveCondition = DirectiveCondition;

  constructor(private resourceService: ResourceService,
    private toastrService: ToastrService,
    private popoverController: PopoverController,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void { }

  setAsFavorite(event: any, report: ReportConfig) {
    event.stopPropagation();
    event.preventDefault();

    this.resourceService.put<ReportConfig>(ResourcesEndpointEnum.PUT_REPORT_FAVORITE, {}, { reportID: report.ReportID })
      .subscribe({
        next: () => {
          report.UsersFavorite = !report.UsersFavorite;
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.translateService.get('Error when toggling favorite report').subscribe(t => {
            this.toastrService.error(t);
          })
          throwError(() => err);
        },
        complete: () => { }
      });
  }

  openDescription(event: any, description: any) {

    let options = [];
    options = [{ text: description }];

    this.popoverController.create({
      component: ConfigurationPopoverComponent,
      event,
      componentProps: { options, textSize: '15px' },
      showBackdrop: false
    }).then((popover) => {
      void popover.present();
    })
  }

  shortText(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.openDescription(event, this.report.Description)
  }
}