import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthEventsService {
    private unauthorizedErrorSource = new Subject<void>();
    private forbiddenErrorSource = new Subject<void>();

    public unauthorizedError$ = this.unauthorizedErrorSource.asObservable();
    public forbiddenError$ = this.forbiddenErrorSource.asObservable();

    public emitUnauthorizedError(): void {
        this.unauthorizedErrorSource.next();
    }
    public emitForbiddenError(): void {
        this.forbiddenErrorSource.next();
    }
}