import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { combineLatest, map, Observable, of, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appRequiredRoles]',
  standalone: true
})

export class RequiredRolesDirective implements OnInit, OnDestroy {
  private _roles: DirectiveRole[] = [];
  private _condition: DirectiveCondition = DirectiveCondition.AND;
  private _hasView = false;
  private destroy$ = new Subject<void>();

  @Input() set appRequiredRoles(roles: DirectiveRole[]) {
    this._roles = roles || [];
    this.updateView();
  }
  @Input() set appRequiredRolesCondition(value: DirectiveCondition) {
    this._condition = value || DirectiveCondition.AND;
    this.updateView();
  }

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.updateView();
  }

  private updateView(): void {
    if (!this._roles || this._roles.length === 0) {
      this.viewContainer.clear();
      return;
    }

    // Get observables for the requested roles
    const roleObservables = this.getRoleObservables(this._roles);

    const isAdminObservable = this.authService.isAdmin();
    const isntAdminObservable = this.authService.isNotAdmin();
    const hasPremiumObservable = this.authService.havePremiumLicense();
    const doesntHavePremiumObservable = this.authService.doesntHavePremiumLicense();
    const isOwnerObservable = this.authService.isOwner();
    const isntOwnerObservable = this.authService.isNotOwner();

    // Combine all role observables and apply the condition
    combineLatest([
      combineLatest(roleObservables).pipe(
        map(results => this.applyCondition(results, this._condition))
      ),
      isAdminObservable,
      isntAdminObservable,
      hasPremiumObservable,
      doesntHavePremiumObservable,
      isOwnerObservable,
      isntOwnerObservable
    ])
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(([hasAccess, isAdmin, isOwner, hasPremium]) => {
        this.viewContainer.clear();

        if (hasAccess) {
          const context = {
            $implicint: true,
            isAdmin,
            isOwner,
            hasPremium,
            roles: this._roles
          };
          this.viewContainer.createEmbeddedView(this.templateRef, context);
          this._hasView = true;
        }
      });
  }

  private getRoleObservables(roles: DirectiveRole[]): Observable<boolean>[] {
    return roles.map(role => {
      switch (role) {
        case DirectiveRole.Admin:
          return this.authService.isAdmin();
        case DirectiveRole.NotAdmin:
          return this.authService.isNotAdmin();
        case DirectiveRole.Premium:
          return this.authService.havePremiumLicense();
        case DirectiveRole.NotPremium:
          return this.authService.doesntHavePremiumLicense();
        case DirectiveRole.Owner:
          return this.authService.isOwner();
        case DirectiveRole.NotOwner:
          return this.authService.isNotOwner();
        case DirectiveRole.User:
          return of(true);
        case DirectiveRole.NotUser:
          return of(false);
        default:
          return of(false);
      }
    });
  }

  private applyCondition(results: boolean[], condition: DirectiveCondition): boolean {
    if (results.length === 0) return false;

    switch (condition) {
      case DirectiveCondition.AND:
        return results.every(result => result === true);
      case DirectiveCondition.OR:
        return results.some(result => result === true);
      default:
        return false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

export enum DirectiveCondition {
  AND = 'AND',
  OR = 'OR'
}
export enum DirectiveRole {
  Admin = 'Admin',
  Premium = 'Premium',
  Owner = 'Owner',
  User = 'User',
  NotAdmin = 'NotAdmin',
  NotPremium = 'NotPremium',
  NotOwner = 'NotOwner',
  NotUser = 'NotUser'
}