import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export class FormHelper {
  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @param {FormGroup} formGroup
   * @param {Array<string> | string} path A dot-delimited string or array of strings that define the
   *  path to the array relative to given formGroup.
   * @example (userForm, 'names.firstName')
   * @returns {FormArray}
   */
  static getArray(formGroup: FormGroup, path: Array<string> | string): FormArray {
    return formGroup?.get(path) as FormArray;
  }

  /**
   * Return given ctrl as FormArray
   *
   * @param {AbstractControl} ctrl
   * @returns {FormArray}
   */
  static getAsArray(ctrl: AbstractControl | null): FormArray {
    return ctrl as FormArray;
  }

  /**
   * Return given ctrl as FormControl
   *
   * @param {AbstractControl} ctrl
   * @returns {FormControl}
   */
  static getAsCtrl(ctrl: AbstractControl | null): FormControl {
    return ctrl as FormControl;
  }

  /**
   * Return given ctrl as FormGroup
   *
   * @param {AbstractControl} ctrl
   * @returns {FormGroup}
   */
  static getAsGroup(ctrl: AbstractControl | null | undefined): FormGroup {
    return ctrl as FormGroup;
  }

  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @param {FormGroup} formGroup
   * @param {Array<string> | string} path A dot-delimited string or array of strings that define the
   *  path to the array relative to given formGroup.
   * @example (userForm, 'names.firstName')
   * @returns {FormGroup}
   */
  static getGroup(formGroup: FormGroup, path: Array<string> | string): FormGroup {
    return formGroup?.get(path) as FormGroup;
  }

  /**
   *
   * @param requirements
   * @param toBeValidated
   * @param onDestroy
   */
  static setDynamicValidators(requirements: Array<{ formControl: FormControl, relevantValues: Array<any> }>, toBeValidated: { formControl: FormControl, validators: Array<ValidatorFn> }, onDestroy: Observable<any>): void {
    // Array of subscriptions
    // const array

    for (let req of requirements) {
      // Subscribe to value changes, map to boolean, add subscriptions to array
      // takeUntil(onDestroy)
    }

    // Combine subscriptions into single observable
    // Check for all true values
    // Toggle validators on form control
    // takeUntil(onDestroy)
  }
}
