// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pBI-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

ion-button {
  --border-radius: 8px!important;
}

.embedding-powerBI {
  position: fixed;
  top: 140px;
  bottom: 5px;
}

.pbi-loader-text {
  position: fixed;
  margin-top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--ion-color-primary-contrast);
  font-family: var(--ion-font-family, inherit);
  font-size: 1rem;
}

ion-modal {
  --width: 300px;
  --height: 400px;
  --border-radius: 8px;
  left: 80%;
}

ion-modal ion-datetime {
  height: 382px;
}

ion-padding {
  background-color: var(--ion-color-secondary);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/embedding-report/embedding-report.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AACF;;AACA;EACE,8BAAA;AAEF;;AACA;EACE,eAAA;EACA,UAAA;EACA,WAAA;AAEF;;AACA;EACE,eAAA;EAEA,gBAAA;EACA,SAAA;EACA,gCAAA;EACA,wCAAA;EACA,4CAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,oBAAA;EACA,SAAA;AACF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,4CAAA;AAAF","sourcesContent":[".pBI-button {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\nion-button {\n  --border-radius: 8px!important;\n}\n\n.embedding-powerBI {\n  position: fixed;\n  top: 140px;\n  bottom: 5px;\n}\n\n.pbi-loader-text {\n  position: fixed;\n  // top: 10%;\n  margin-top: 40px;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: var(--ion-color-primary-contrast);\n  font-family: var(--ion-font-family, inherit);\n  font-size: 1rem;\n}\n\nion-modal {\n  --width: 300px;\n  --height: 400px;\n  --border-radius: 8px;\n  left: 80%;\n  // bottom: 40%;\n}\n\nion-modal ion-datetime {\n  height: 382px;\n}\n\nion-padding {\n  background-color: var(--ion-color-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
