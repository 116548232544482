import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesStore {
  private readonly _roles$: BehaviorSubject<Array<string> | null> = new BehaviorSubject<Array<string> | null>(null);

  get roles$(): Observable<Array<string> | null> {
    return this._roles$.asObservable();
  }

  set roles(roles: Array<string> | null) {
    this._roles$.next(roles);
  }
}
